import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import UpdateForm from "../../Components/Forms/UpdateForm";
import functions from "../../functions";

class UpdateProduct extends React.Component {
  state = {
    productId: this.props.location.state.id,
  };

  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Update Product"
              previousPageTitle="Dashboard"
              //   buttons={<this.buttons />}
            />
            <UpdateForm product_id={this.state.productId} />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default UpdateProduct;
