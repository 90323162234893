import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import { Link } from "react-router-dom";
import FinTable from "../../Components/Tables/FinTable";

class FinishedOrders extends React.Component {
  buttons = () => {
    return (
      <>
        {/* <button className="btn ripple btn-primary">
          <i className="fab fa-amazon-pay"></i> Recent Orders
        </button> */}
      </>
    );
  };

  render() {
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Finished Orders"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <FinTable />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default FinishedOrders;
