import React from 'react'

class Footer extends React.Component{

    render(){
        return(
            <div className="main-footer text-center">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
                        <span>Designed and Developed by{" "}
                            <a >ARAKNERD</a>.
                            </span>
						</div>
					</div>
				</div>
			</div>
        )
    }

}

export default Footer