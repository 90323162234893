import React, { Component } from 'react'
import ajax from '../../ajax';
import functions from '../../functions';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import ActivityLoader from '../Common/ActivityLoader';

export default class RegisterUser extends Component {
    state = {
        shop_id: functions.sessionGuard(),
        waiters: false,
        cashiers: false,
        agents: false,
        loading: false,
        info: false
    }

    componentDidMount() {
        this.listWaiters();
        this.listAgents();
        this.listCashiers();
    }


    listWaiters = async () => {
        const server_response = await ajax.listWaiters(this.state.shop_id);

        if (server_response.status == "OK") {
            this.setState({
                waiters: server_response.details,
            });
        }
    };
    listCashiers = async () => {
        const server_response = await ajax.listCashiers(this.state.shop_id);

        if (server_response.status == "OK") {
            this.setState({
                cashiers: server_response.details,
            });
        }
    };
    listAgents = async () => {
        const server_response = await ajax.listAgents(this.state.shop_id);

        if (server_response.status == "OK") {
            this.setState({
                agents: server_response.details,
            });
        }
    };


    render() {
        const { waiters, agents, cashiers } = this.state;
        console.log(waiters)

        return (

            <div class="modal" id="modaldemoxx">
                <div class="modal-dialog modal-lg" role="">
                    <div class="modal-content modal-content-demo">
                        <div class="modal-header">
                            <h6 class="modal-title">Shop Employees</h6><button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    {this.state.info && this.state.info}
                                    {this.state.loading && <ActivityLoader />}


                                    <div className="text-wrap">
                                        <div className="example">
                                            <div className="bg-gray-300 nav-bg">
                                                <nav className="nav nav-tabs">
                                                    <a className="nav-link active" data-toggle="tab" href="#tabCont1">Waiters</a>
                                                    <a className="nav-link" data-toggle="tab" href="#tabCont2">Agents</a>
                                                    <a className="nav-link" data-toggle="tab" href="#tabCont3">Cashiers</a>
                                                </nav>
                                            </div>
                                            <div className="card-body tab-content">
                                                <div className="tab-pane active show" id="tabCont1">
                                                    <div className='table-responsive'>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>#NO</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Contact</th>
                                                                    <th>Email</th>
                                                                    <th>Shift</th>
                                                                    <th>Commission Rate</th>
                                                                    <th>Commission Balance</th>
                                                                </tr>
                                                            </thead>
                                                            {waiters && waiters != '404' && waiters.map((waiter, key) => <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{waiter.user.first_name}</td>
                                                                <td>{waiter.user.last_name}</td>
                                                                <td>{waiter.user.phone}</td>
                                                                <td>{waiter.user.email}</td>
                                                                <td>{waiter.shift}</td>
                                                                <td>{waiter.commission_rate}</td>
                                                                <td>{waiter.user.commission}</td>
                                                            </tr>)}
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="tabCont2">
                                                    <div className='table-responsive'>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>#NO</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Contact</th>
                                                                    <th>Email</th>
                                                                    <th>Commission Balance</th>
                                                                    <th>Delivery Fees</th>
                                                                </tr>
                                                            </thead>
                                                            {agents && agents != '404' && agents.map((agent, key) => <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{agent.user.first_name}</td>
                                                                <td>{agent.user.last_name}</td>
                                                                <td>{agent.user.phone}</td>
                                                                <td>{agent.user.email}</td>
                                                                <td>{agent.user.commission}</td>
                                                                <td>{agent.delivery_fees}</td>
                                                            </tr>)}
                                                        </table>
                                                    </div>                                                </div>
                                                <div className="tab-pane" id="tabCont3">
                                                    <div className='table-responsive'>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>#NO</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Contact</th>
                                                                    <th>Email</th>
                                                                </tr>
                                                            </thead>
                                                            {cashiers && cashiers != '404' && cashiers.map((cashier, key) => <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{cashier.user.first_name}</td>
                                                                <td>{cashier.user.last_name}</td>
                                                                <td>{cashier.user.phone}</td>
                                                                <td>{cashier.user.email}</td>
                                                            </tr>)}
                                                        </table>
                                                    </div>                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

