import React from "react";

class AlertError extends React.Component {
  render() {
    return (
      <div className="alert alert-danger mg-b-10" role="alert">
        <>
          {this.props.message && (
            <div className="alert alert-danger" role="alert">
              <strong>Error! </strong>
              {this.props.message}
            </div>
          )}
        </>
      </div>
    );
  }
}

export default AlertError;
