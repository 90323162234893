import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import ActivityLoader from "../../Components/Common/ActivityLoader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import { Link } from "react-router-dom";
import DashCard from "../../Components/Common/DashCard";
import Table from "../../Components/Tables/Table";
import ajax from "../../ajax";
import TotalProducts from "../../Components/Cards/TotalProducts";
import TotalPendingOrders from "../../Components/Cards/TotalPendingOrders";
import TotalSalesToday from "../../Components/Cards/TotalSalesToday";
import TotalSales from "../../Components/Cards/TotalSales";
import PaymentMethods from "../../Components/charts/PaymentMethods";
import OrderTypes from "../../Components/charts/OrderTypes";

class Dashboard extends React.Component {
  state = {
    shop_id: functions.sessionGuard(),
    message: false,
    loading: false,
    shopInfo: false,
  };

  componentDidMount() {
    this.getShopInfo();
  }

  getShopInfo = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getShopInfo(this.state.shop_id);
    // console.log(server_response)
    this.setState({ loading: false });
    // console.log(server_response);
    if (server_response.status == "OK") {
      this.setState({
        shopInfo: server_response.details,
        message: server_response.message,
      });
      localStorage.setItem("shop_id", server_response.details.shop_id);
      localStorage.setItem("shop_name", server_response.details.shop_name);
    } else {
      this.setState({
        message: server_response.message,
      });
    }
  };

  buttons = () => {
    return (
      <>
        {/* <Link className="btn ripple btn-light" to="/products">
          <i className="fas fa-dove"></i> Create Order
        </Link>
        <button className="btn ripple btn-primary">
          <i className="fab fa-amazon-pay"></i> Recent Orders
        </button> */}
      </>
    );
  };

  render() {
    const { shopInfo } = this.state;
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          {shopInfo && (
            <div className="container-fluid">
              <PageHeader
                title={shopInfo.shop_name}
                previousPageTitle="Dashboard"
                buttons={<this.buttons />}
              />

              <div className="row">
                <TotalProducts />
                <TotalPendingOrders />
                <TotalSalesToday />
                <TotalSales />
              </div>
              <Table title={shopInfo.shop_name} />

              <div className="row mb-5">
                <PaymentMethods />
                <OrderTypes />
              </div>

            </div>

          )}
          {!shopInfo && <ActivityLoader />}
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />

        </div>
      </div>
    );
  }
}

export default Dashboard;
