import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../../Components/Common/ActivityLoader";

class ProductAdditives extends React.Component {
  state = {
    pdts: false,
    loading: false,
    shop_id: functions.shopId(),
    name: this.props.location.state.info.type,
    settings: false,
    category: "",
  };

  componentDidMount() {
    this.getAdditives();
    this.listAdditiveSettings();
  }

  getAdditives = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.listProductAdditives(
      this.props.location.state.info.id
    );
    this.setState({ loading: false });
    if (server_response.status === "OK") {
      this.setState({
        pdts: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        pdts: "404",
        message: server_response.message,
      });
    }
  };

  listAdditiveSettings = async () => {
    const server_response = await ajax.getAdditives();
    if (server_response.status === "OK") {
      this.setState({
        settings: server_response.details,
      });
    }
  };

  buttons = () => {
    return (
      <>
        {/* <button className="btn ripple btn-primary">
          <i className="fab fa-amazon-pay"></i> Recent Orders
        </button> */}
      </>
    );
  };

  deleteAdditive = (id) => {
    if (window.confirm("Are you sure you want to delete this Additive?")) {
      const server_response = ajax.deleteProductAdditive(id);
      console.log(server_response);
      if (server_response) {
        this.setState(
          {
            pdts: false,
          },
          () => {
            this.getAdditives();
          }
        );
      }
    }
  };

  onChangeCategory = (e) => {
    this.setState({ category: e.target.value });
  };

  onsubmit = async () => {
    const server_response = await ajax.createProductAdditive(
      this.state.category,
      this.props.location.state.info.id
    );
    console.log(server_response);
    if (server_response.status === "OK") {
      this.setState(
        {
          message: server_response.message,
          pdts: false,
          category: "",
        },
        () => this.getAdditives()
      );
    }
  };
  render() {
    const { pdts, settings, category } = this.state;
    console.log(pdts);

    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title={"Product " + this.state.name}
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="card-title mb-3">
                        {" "}
                        Product {this.state.name}
                      </h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>#No</th>
                            {/* <th>Product Id</th> */}
                            <th>Additive Category</th>
                            <th>Additive Name</th>
                            <th>Additive Price</th>
                            <th>delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pdts &&
                            pdts !== "404" &&
                            pdts
                              .filter(
                                (item) =>
                                  item.additive.additive_category.category ==
                                  this.state.name
                              )
                              .map((pdt, key) => (
                                <tr>
                                  <td>{key + 1}</td>
                                  {/* <td>{pdt["product_id"]}</td> */}
                                  <td>
                                    {
                                      pdt["additive"]["additive_category"][
                                        "category"
                                      ]
                                    }
                                  </td>
                                  <td>{pdt["additive"]["name"]}</td>
                                  <td>{pdt["additive"]["price"]}</td>

                                  <td className="text-danger">
                                    {" "}
                                    <i
                                      className="fas fa-trash fa-lg"
                                      onClick={() =>
                                        this.deleteAdditive(pdt["id"])
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {pdts && pdts === "404" && (
                    <h4 className=" text-danger mt-5 ml-5">
                      No Additive Found!!!!
                    </h4>
                  )}
                  {!pdts && <ActivityLoader />}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="card-title mb-3">
                        {" "}
                        Add {this.state.name}
                      </h6>
                    </div>

                    <div>
                      <div className="form-group">
                        <label className="">Additive</label>
                        <select
                          className="form-control"
                          value={category}
                          onChange={this.onChangeCategory}
                        >
                          <option unselectable>
                            {" "}
                            -- select {this.state.name} to add --
                          </option>
                          {settings &&
                            settings != "404" &&
                            settings
                              .filter(
                                (item) =>
                                  item.additive_category.category ==
                                  this.state.name
                              )
                              .map((item, key) => (
                                <option key={key} value={item.id}>
                                  {item.name + " => " + item.price}
                                </option>
                              ))}
                        </select>
                      </div>

                      <button
                        onClick={this.onsubmit}
                        className="btn ripple btn-main-primary btn-block"
                      >
                        Add Extra
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default ProductAdditives;
