import React, { Component } from "react";
import ajax from "../../ajax";
import functions from "../../functions";
import DashCard from "../Common/DashCard";
import music from "../../audio/2.wav";
import { Howl, Howler } from "howler";

var sound = new Howl({
  src: music,
});

export default class TotalPendingOrders extends Component {
  state = {
    shop_id: functions.sessionGuard(),
    total: false,
  };

  async componentDidMount() {
    await this.getTotalPendingOrders();
    this.timerActual = setTimeout(
      (this.func1 = async () => {
        await this.getTotalPendingOrders();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }),
      2000
    );
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state.total);
    if (!prevState.total.total) {
      return;
    } else {
      if (prevState.total.total < this.state.total.total) {
        sound.play();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerActual);
  }

  getTotalPendingOrders = async () => {
    const server_response = await ajax.totalPendingOrders(this.state.shop_id);
    if (server_response.status == "OK") {
      this.setState({
        total: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        message: server_response.message,
      });
    }
  };
  render() {
    const { total } = this.state;
    const final = total.total ? total.total : "0";
    return (
      <DashCard
        title="Total Pending Orders"
        value={final}
        icon={"fas fa-chart-bar"}
      />
    );
  }
}
