import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../../Components/Common/ActivityLoader";

class Dashboard extends React.Component {
  state = {
    pdts: false,
    loading: false,
    shop_id: functions.shopId(),
    type: false,
  };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getProducts(this.state.shop_id);
    this.setState({ loading: false });
    if (server_response.status === "OK") {
      this.setState({
        pdts: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        pdts: "404",
        message: server_response.message,
      });
    }
  };

  buttons = () => {
    return (
      <>
        {/* <button className="btn ripple btn-primary">
          <i className="fab fa-amazon-pay"></i> Recent Orders
        </button> */}
      </>
    );
  };

  deleteProduct = (id) => {
    window.alert("Function currently in active!!!! contaxt admin to proceed!!");
    return;
    if (window.confirm("Are you sure you want to delete this product?")) {
      const server_response = ajax.deleteProduct(id);
      console.log(server_response);
      if (server_response) {
        this.setState(
          {
            pdts: false,
          },
          () => {
            this.getProducts();
          }
        );
      }
    }
  };

  updateProduct = (id) => {
    if (window.confirm("Are you sure you want to Update this product?")) {
      this.props.history.push({
        pathname: "/update/product",
        state: { id: id },
      });
    }
  };

  viewExtras = (id) => {
    this.props.history.push({
      pathname: "/product/additives",
      state: { info: { type: "Extras", id: id } },
    });
  };

  viewDrinks = (id) => {
    this.props.history.push({
      pathname: "/product/additives",
      state: { info: { type: "Drinks", id: id } },
    });
  };

  viewSnacks = (id) => {
    this.props.history.push({
      pathname: "/product/additives",
      state: { info: { type: "Snacks", id: id } },
    });
  };

  viewSides = (id) => {
    this.props.history.push({
      pathname: "/product/additives",
      state: { info: { type: "Sides", id: id } },
    });
  };

  viewSubs = (id) => {
    this.props.history.push({
      pathname: "/sub/products",
      state: { info: { type: "Subs", id: id } },
    });
  };

  render() {
    const { pdts } = this.state;
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Products"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <div className="row mb-5">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped text-nowrap">
                      <thead>
                        <tr>
                          <th>#No</th>
                          {/* <th>Product Id</th> */}
                          <th>Product Code</th>
                          <th>Product Category</th>
                          <th>Product Name</th>
                          <th>Product Price</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Image</th>
                          <th>Extras</th>
                          <th>Sides</th>
                          <th>Snacks</th>
                          <th>Drinks</th>
                          <th>Sub Products</th>
                          <th>edit</th>
                          <th>delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdts &&
                          pdts !== "404" &&
                          pdts.map((pdt, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              {/* <td>{pdt["product_id"]}</td> */}
                              <td>{pdt["code"]}</td>
                              <td>{pdt["category"]["category"]}</td>
                              <td>{pdt["name"]}</td>
                              <td>{pdt["price"]}</td>
                              <td>{pdt["short_desc"]}</td>
                              <td
                                style={{
                                  whiteSpace: "pre-wrap",
                                  maxWidth: "300px",
                                  minWidth: "300px",
                                }}
                              >
                                {pdt["long_desc"]}
                              </td>
                              <td>
                                <img
                                  src={pdt["image"]}
                                  style={{ width: "70px", height: "50px" }}
                                />
                              </td>
                              <td>
                                {pdt["extras"] == "0" ? (
                                  "No Extras"
                                ) : (
                                  <span
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      this.viewExtras(pdt["product_id"])
                                    }
                                  >
                                    View Extras
                                  </span>
                                )}
                              </td>
                              <td>
                                {pdt["sides"] == "0" ? (
                                  "No Sides"
                                ) : (
                                  <span
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      this.viewSides(pdt["product_id"])
                                    }
                                  >
                                    View Sides
                                  </span>
                                )}
                              </td>
                              <td>
                                {pdt["snacks"] == "0" ? (
                                  "No Snacks"
                                ) : (
                                  <span
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      this.viewSnacks(pdt["product_id"])
                                    }
                                  >
                                    View Snacks
                                  </span>
                                )}
                              </td>
                              <td>
                                {pdt["drinks"] == "0" ? (
                                  "No Drinks"
                                ) : (
                                  <span
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      this.viewDrinks(pdt["product_id"])
                                    }
                                  >
                                    View Drinks
                                  </span>
                                )}
                              </td>
                              <td>
                                {pdt["subs"] == "0" ? (
                                  "No Sub Products"
                                ) : (
                                  <span
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      this.viewSubs(pdt["product_id"])
                                    }
                                  >
                                    View Sub Products
                                  </span>
                                )}
                              </td>
                              <td className="text-warning">
                                {" "}
                                <i
                                  className="fas fa-edit fa-lg"
                                  onClick={() =>
                                    this.updateProduct(pdt["product_id"])
                                  }
                                ></i>
                              </td>
                              <td className="text-danger">
                                {" "}
                                <i
                                  className="fas fa-trash fa-lg"
                                  onClick={() =>
                                    this.deleteProduct(pdt["product_id"])
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {pdts && pdts === "404" && (
                  <h4 className=" text-danger mt-5 ml-5">
                    No Products Found!!!!
                  </h4>
                )}
                {!pdts && <ActivityLoader />}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default Dashboard;
