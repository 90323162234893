import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts';
import ajax from '../../ajax';
import functions from '../../functions';
import AlertError from '../Common/AlertError';
import ActivityLoader from '../Common/ActivityLoader';


export default class OrderTypes extends Component {
    state = {
        shop_id: functions.sessionGuard(),
        loading: false,
        info: false,
        series: [],
        labels: [],

    };



    componentDidMount() {
        this.getChartData();
    }

    getChartData = async () => {
        this.setState({ loading: true })

        const server_response = await ajax.paymentMethodsPieData(this.state.shop_id);

        this.setState({ loading: false })

        if (server_response.status == 'OK') {
            this.setState({ labels: server_response.details['labels'] }, () => {
                this.setState({ series: server_response.details['series'] })
            })
        } else {
            this.setState({ info: <AlertError message={server_response.message} /> })
        }
    }

    render() {
        const { loading, labels } = this.state;
        const options = {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        };
        return (
            <div className='col-lg-6 col-sm-12'>
                <div className='card custom-card'>
                    <div className='card-body'>
                        <div className='border-bottom'>
                            <h6 className="card-title mb-1">Payment method</h6>
                            <p className="text-muted card-sub-title">
                                Todays total sales in different payment methods
                            </p>
                        </div>
                        {this.state.info && this.state.info}

                        {!loading && <ReactApexChart options={options} series={this.state.series} type="pie" height="400" />}
                        {loading && <ActivityLoader />}
                    </div>
                </div>
            </div>
        )
    }
}
