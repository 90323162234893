import React, { Component } from "react";
import ajax from "../../ajax";
import functions from "../../functions";
import ActivityLoader from "../Common/ActivityLoader";
import FinItemsModal from "../Modals/FinItemsModal";

export default class FinTable extends Component {
  state = {
    shop_id: functions.sessionGuard(),
    shop_name: functions.shopName(),
    items: false,
    loading: false,
    message: false,
    order: false,
  };

  componentDidMount() {
    this.getFinishedOrders();
  }

  getFinishedOrders = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getShopPendingOrdersFinished(
      this.state.shop_id
    );
    this.setState({ loading: false });

    if (server_response.status == "OK") {
      this.setState({
        items: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        items: "404",
        message: server_response.message,
      });
    }
  };

  viewItems = async (id) => {
    this.setState({ loading: true });
    const server_response = await ajax.getOrderItems(id);
    this.setState({ loading: false });
    console.log(server_response);
    if (server_response.status == "OK") {
      this.setState({
        message: server_response.message,
        order: server_response.details,
      });
    } else {
      this.setState({
        message: server_response.message,
        order: "404",
      });
    }
  };

  render() {
    const { items, shop_name } = this.state;
    console.log(shop_name);
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-xl-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="card-title mb-1">Finished Orders</h6>

                  <p className="text-muted card-sub-title">
                    Mando Express Orders thats have been Proccessed
                  </p>
                </div>
                {this.state.loading && <ActivityLoader />}
                <div className="table-responsive">
                  <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>
                        <th>Order No</th>
                        <th>Order Total</th>
                        <th>Created by</th>
                        <th>Recipient</th>
                        <th>User Type</th>
                        <th>Payment Method</th>
                        <th>Order Type</th>
                        <th>View Order Items</th>
                        <th>Confirm Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items &&
                        items !== "404" &&
                        items.map((item, key) => (
                          <tr key={key}>
                            <td>#{key + 1}</td>
                            <td>{item.order_id}</td>
                            <td>{item.total ? item.total : "0"}</td>
                            <td>{item.user.first_name != "Not set" ? item.user.first_name + " " : ""} {item.user.last_name} <br /> {item.user.phone}</td>
                            <td>{item.name} <br /> {item.contact}</td>
                            <td>{item.user_type == 3 ? "Customer" : item.user_type == 4 ? "Agent" : item.user_type == 5 ? "Cashier" : item.user_type == 6 ? "Waiter" : ""}</td>
                            <td>{item.payment_method == 1 ? "Mobile Money" : item.payment_method == 2 ? "Quickpost" : item.payment_method == 3 ? "Pay on Delivery" : item.payment_method == 4 ? "Debt" : ""}</td>
                            <td>{item.order_type == 1 ? "Mando Express" : item.order_type == 2 ? "Jumia" : item.order_type == 3 ? "Glovo" : item.order_type == 4 ? "Online" : ""}</td>
                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                className={"badge p-2 badge-success"}
                                data-target="#modaldemo3"
                                data-toggle="modal"
                                onClick={() => this.viewItems(item.order_id)}
                              >
                                View Order Items
                              </span>
                            </td>

                            <td>
                              {item.confirmed == 1 ? "Confirmed" : <span
                                style={{ cursor: "pointer" }}
                                className={"badge p-2 badge-primary"}
                                onClick={() => this.confirmOrder(item.order_id)}
                              >
                                Confirm Order
                              </span>}
                            </td>

                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FinItemsModal order={this.state.order} title={this.state.shop_name} />
      </div>
    );
  }
}
