import React, { Component } from 'react'
import ajax from '../../ajax';
import functions from '../../functions';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import ActivityLoader from '../Common/ActivityLoader';

export default class RegisterUser extends Component {
    state = {
        shop_id: functions.sessionGuard(),
        shopInfo: false,
        fname: '',
        lname: '',
        email: '',
        phone: '',
        shift: '',
        fees: '',
        info: false,
        loading: false
    }

    componentDidMount() {
        this.getShopInfo();
    }


    getShopInfo = async () => {
        const server_response = await ajax.getShopInfo(this.state.shop_id);

        if (server_response.status == "OK") {
            this.setState({
                shopInfo: server_response.details,
            });
        }
    };

    onChangeFname = (event) => {
        this.setState({ fname: event.target.value })
    }

    onChangeLname = (event) => {
        this.setState({ lname: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangePhone = (event) => {
        this.setState({ phone: event.target.value })
    }

    onChangeShift = (event) => {
        this.setState({ shift: event.target.value })
    }

    onChangeFees = (event) => {
        this.setState({ fees: event.target.value })
    }

    registerUser = () => {

        if (this.props.person == 'Waiter') {
            this.registerWaiter();
            return;
        }

        if (this.props.person == 'Agent') {
            this.registerAgent();
            return;
        }

        if (this.props.person == 'Cashier') {
            this.registerCashier();
            return;
        }


    }

    registerWaiter = async () => {
        const { fname, lname, email, phone, shift, fees } = this.state;

        if (!fname || !lname || !email || !phone || !shift) {
            this.setState({ info: <AlertError message="Please fill in all the fields" /> })
            return;
        }

        this.setState({ loading: true })
        const server_response = await ajax.registerWaiter(
            this.state.shop_id,
            fname,
            lname,
            phone,
            shift,
            email
        );
        this.setState({ loading: false, fname: '', lname: '', email: '', phone: '', shift: '' })

        if (server_response.status == 'OK') {
            this.setState({ info: <AlertSuccess message={server_response.message} /> })
            return;
        }

        this.setState({ info: <AlertError message={server_response.message} /> })
    }

    registerAgent = async () => {
        const { fname, lname, email, phone, shift, fees } = this.state;

        if (!fname || !lname || !email || !phone || !fees) {
            this.setState({ info: <AlertError message="Please fill in all the fields" /> })
            return;
        }

        this.setState({ loading: true })
        const server_response = await ajax.registerAgent(
            this.state.shop_id,
            fname,
            lname,
            phone,
            fees,
            email
        );
        this.setState({ loading: false, fname: '', lname: '', email: '', phone: '', fees: '' })

        if (server_response.status == 'OK') {
            this.setState({ info: <AlertSuccess message={server_response.message} /> })
            return;
        }

        this.setState({ info: <AlertError message={server_response.message} /> })
    }

    registerCashier = async () => {
        const { fname, lname, email, phone } = this.state;

        if (!fname || !lname || !email || !phone) {
            this.setState({ info: <AlertError message="Please fill in all the fields" /> })
            return;
        }

        this.setState({ loading: true })
        const server_response = await ajax.registerCashier(
            this.state.shop_id,
            fname,
            lname,
            phone,
            email
        );
        this.setState({ loading: false, fname: '', lname: '', email: '', phone: '' })

        if (server_response.status == 'OK') {
            this.setState({ info: <AlertSuccess message={server_response.message} /> })
            return;
        }

        this.setState({ info: <AlertError message={server_response.message} /> })
    }


    render() {
        const { shopInfo } = this.state;
        const { person } = this.props;
        return (

            <div class="modal" id="modaldemo8">
                <div class="modal-dialog modal-lg" role="">
                    <div class="modal-content modal-content-demo">
                        <div class="modal-header">
                            <h6 class="modal-title">Register {person}</h6><button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    {this.state.info && this.state.info}
                                    {this.state.loading && <ActivityLoader />}
                                    <div className="">
                                        <div className="form-group">
                                            <label className="">{person + "'s"} first name</label>
                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                value={this.state.fname}
                                                onChange={this.onChangeFname}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="">{person + "'s"} last name</label>
                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                value={this.state.lname}
                                                onChange={this.onChangeLname}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="">{person + "'s"} phone number</label>
                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                value={this.state.phone}
                                                onChange={this.onChangePhone}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="">{person + "'s"} email</label>
                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                value={this.state.email}
                                                onChange={this.onChangeEmail}
                                            />
                                        </div>

                                        {person == "Waiter" && <div className="form-group">
                                            <label className="">{person + "'s"} shift</label>
                                            <select
                                                className="form-control"
                                                value={this.state.shift}
                                                onChange={this.onChangeShift}
                                            >
                                                <option unselectable> -- select shift --</option>

                                                <option value="Day">Day</option>
                                                <option value="Night">Night</option>
                                            </select>
                                        </div>}
                                        {person == "Agent" && <div className="form-group">
                                            <label className="">{person + "'s"} delivery fees</label>
                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                value={this.state.fees}
                                                onChange={this.onChangeFees}
                                            />
                                        </div>}

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn ripple btn-success" onClick={() => this.registerUser()} type="button">Register {person}</button>
                            <button class="btn ripple btn-secondary" data-dismiss="modal" type="button">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
