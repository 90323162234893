import React, { Component } from 'react'
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from './ActivityLoader';

export default class ProfileMenu extends Component {
    state = {
        shop_id: functions.sessionGuard(),
        shopInfo: false
    }

    componentDidMount() {
        this.getShopInfo();

    }


    getShopInfo = async () => {
        const server_response = await ajax.getShopInfo(this.state.shop_id);

        if (server_response.status == "OK") {
            this.setState({
                shopInfo: server_response.details,
            });
        }
    };


    render() {
        const { shopInfo } = this.state;
        return (
            <>
                <div className="dropdown main-profile-menu">
                    <a className="main-img-user" href="#"><img alt="avatar" style={{ borderRadius: '50%' }} src="assets/img/users/avatar.png" /></a>
                    <div className="dropdown-menu">
                        <div className="header-navheading">
                            {shopInfo && <><h6 className="main-notification-title">{shopInfo.shop_name}</h6>
                                <p className="main-notification-text">{shopInfo.phone}</p></>}
                            {!shopInfo && <ActivityLoader />}
                        </div>
                        {/* <a className="dropdown-item border-top" href="#">
                    <i className="fe fe-user" /> My Profile
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fe fe-edit" /> Edit Profile
                  </a> */}
                        {/* <a className="dropdown-item" href="#">
                            <i className="fe fe-settings" /> Settings
                        </a> */}
                        <a className="dropdown-item modal-effect" onClick={() => this.props.onClick("Waiter")} data-effect="effect-fall" data-toggle="modal" href="#modaldemo8">
                            <i className="fe fe-user-plus" /> Register waiter
                        </a>
                        <a className="dropdown-item  modal-effect" onClick={() => this.props.onClick("Cashier")} data-effect="effect-fall" data-toggle="modal" href="#modaldemo8">
                            <i className="fe fe-user-plus" /> Register cashier
                        </a>
                        <a className="dropdown-item  modal-effect" onClick={() => this.props.onClick("Agent")} data-effect="effect-fall" data-toggle="modal" href="#modaldemo8">
                            <i className="fe fe-user-plus" /> Register agent
                        </a>
                        <a className="dropdown-item  modal-effect" data-effect="effect-fall" data-toggle="modal" href="#modaldemoxx">
                            <i className="fe fe-user-plus" /> Employees
                        </a>
                        {/* <a className="dropdown-item" href="#">
                            <i className="fe fe-pie-chart" /> Daily analysis report
                        </a> */}
                        <a className="dropdown-item" href="#" onClick={this.onLogout}>
                            <i className="fe fe-power" /> Sign Out
                        </a>
                    </div>
                </div>
            </>

        )
    }
}
