import React, { Component } from "react";
import ajax from "../ajax";
import functions from "../functions";
import AlertSuccess from "../Components/Common/AlertSuccess";
import AlertError from "../Components/Common/AlertError";
import ActivityLoader from "../Components/Common/ActivityLoader";

export default class ResetPassword extends Component {
  state = {
    user_id: functions.user(),
    pwd: "",
    pwd2: "",
    loading: false,
  };

  onChangePwd = (event) => {
    this.setState({
      pwd: event.target.value,
    });
  };

  onChangePwd2 = (event) => {
    this.setState({
      pwd2: event.target.value,
    });
  };

  changePwd = async () => {
    const { pwd, pwd2, user_id } = this.state;

    if (!pwd || !pwd2) {
      this.setState({
        message: <AlertError message="please fill in all fields please" />,
      });
    } else {
      if (pwd !== pwd2) {
        this.setState({
          message: <AlertError message="Unsimillar Passwords Entered" />,
        });
      } else {
        this.setState({ loading: true });
        const server_response = await ajax.secureAccount(user_id, pwd);
        this.setState({ loading: false });
        if (server_response.status == "OK") {
          this.setState(
            {
              message: <AlertSuccess message={server_response.message} />,
            },
            () => {
              window.localStorage.removeItem("shop_id");
              window.localStorage.removeItem("user_id");
              window.localStorage.removeItem("secure");

              window.localStorage.setItem(
                "message",
                "Password Changed Successfully Login To Continue"
              );

              window.location.replace("/");
            }
          );
        } else {
          this.setState({
            message: server_response.message,
          });
        }
      }
    }
  };
  render() {
    return (
      <div>
        <div className="page main-signin-wrapper">
          <div className="row text-center pl-0 pr-0 ml-0 mr-0">
            <div className="col-lg-3 col-md-12 d-block mx-auto">
              <div className="text-center mb-2">
                <img
                  src="images/logo.png"
                  style={{ width: "150px", height: "150px" }}
                  className="header-brand-img"
                  alt="logo"
                />
              </div>
              <div className="card custom-card">
                <div className="card-body">
                  <h4 className="text-center">Set Your Password</h4>
                  {this.state.message && this.state.message}
                  {this.state.loading && <ActivityLoader />}
                  <div className="form-group text-left">
                    <label>New Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your password"
                      type="password"
                      onChange={this.onChangePwd}
                    />
                  </div>
                  <div className="form-group text-left">
                    <label>Confirm Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your password"
                      type="password"
                      onChange={this.onChangePwd2}
                    />
                  </div>
                  <button
                    style={{ backgroundColor: "#ff8330" }}
                    className="btn ripple  btn-block"
                    onClick={this.changePwd}
                  >
                    Set Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
