import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertSuccess from "../Common/AlertSuccess";
import AlertError from "../Common/AlertError";

export default class UpdateAdditiveForm extends Component {
  state = {
    additive_id: this.props.product_id,
    name: "",
    price: "",
    category: "",
    loading: false,
    categories: false,
  };

  componentDidMount() {
    this.listCategories();
    this.getAdditives();
  }

  onchangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  onChangeCategory = (event) => {
    this.setState({
      category: event.target.value,
    });
  };
  onchangePrice = (event) => {
    this.setState({
      price: event.target.value,
    });
  };

  getAdditives = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getAdditives();
    this.setState({ loading: false });
    if (server_response.status === "OK") {
      server_response.details
        .filter((additive) => additive.id == this.state.additive_id)
        .map((item) => {
          this.setState({
            name: item.name,
            price: item.price,
            category: item.additive_category.id,
          });
        });
    } else {
      this.setState({
        pdts: "404",
        message: server_response.message,
      });
    }
  };

  onsubmit = async () => {
    const { name, price, category, additive_id } = this.state;
    if (!name || !price || !category) {
      this.setState({
        info: <AlertError message="fill all fields please" />,
      });
    } else {
      this.setState({ loading: true });
      const server_response = await ajax.updateAdditiveSetting(
        additive_id,
        name,
        price,
        category
      );
      this.setState({
        loading: false,
        name: "",
        price: "",
        category: "",
      });
      if (server_response.status == "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.message} />,
        });
      } else {
        this.setState({
          info: <AlertError message={server_response.message} />,
        });
      }
    }
  };

  listCategories = async () => {
    const server_response = await ajax.listAdditiveCategories();
    if (server_response.status == "OK") {
      this.setState({
        categories: server_response.details,
      });
    } else {
      this.setState({
        categories: "404",
        message: server_response.message,
      });
    }
  };
  render() {
    const { info, additive_id, name, category, price, categories } = this.state;
    console.log(additive_id);
    return (
      <div>
        <div className="row mb-5">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="card-title mb-1">Add Additive</h6>
                  <p className="text-muted card-sub-title">
                    All fields in this form are compalsary!
                  </p>
                  {this.state.loading && <ActivityLoader />}
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    {this.state.info && info}
                    <div className="">
                      <div className="form-group">
                        <label className="">Additive Name</label>
                        <input
                          className="form-control"
                          required=""
                          type="text"
                          value={name}
                          onChange={this.onchangeName}
                        />
                      </div>

                      <div className="form-group">
                        <label className="">Additive Price</label>
                        <input
                          className="form-control"
                          required=""
                          type="text"
                          value={price}
                          onChange={this.onchangePrice}
                        />
                      </div>
                      <div className="form-group">
                        <label className="">Additive Category</label>
                        <select
                          className="form-control"
                          value={category}
                          onChange={this.onChangeCategory}
                        >
                          <option unselectable> -- select category --</option>
                          {categories &&
                            categories != "404" &&
                            categories.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.category}
                              </option>
                            ))}
                        </select>
                      </div>

                      <button
                        onClick={this.onsubmit}
                        className="btn ripple btn-main-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
