import React, { Component } from "react";
import ajax from "../../ajax";
import functions from "../../functions";
import DashCard from "../Common/DashCard";

export default class TotalSales extends Component {
  state = {
    shop_id: functions.sessionGuard(),
    total: false,
  };

  async componentDidMount() {
    await this.getTotalSales();
    this.timerActual = setTimeout(
      (this.func1 = async () => {
        await this.getTotalSales();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }),
      2000
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timerActual);
  }

  getTotalSales = async () => {
    const server_response = await ajax.totalSales(this.state.shop_id);
    // console.log(server_response)
    if (server_response.status == "OK") {
      this.setState({
        total: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        message: server_response.message,
      });
    }
  };
  render() {
    const { total } = this.state;
    const final = total.total ? total.total : "0";
    return (
      <DashCard
        title="Cummulative Sales"
        value={"Ugx. " + final}
        icon={"fas fa-chart-bar"}
      />
    );
  }
}
