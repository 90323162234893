import React, { Component } from "react";
import ajax from "../../ajax";
import functions from "../../functions";
import ActivityLoader from "../Common/ActivityLoader";
import ItemsModal from "../Modals/ItemsModal";

export default class Table extends Component {
  state = {
    shop_id: functions.sessionGuard(),
    items: false,
    loading: false,
    message: false,
    order: false,
  };

  async componentDidMount() {
    await this.getPendingOrders();
    this.timerActual = setTimeout(
      (this.func1 = async () => {
        await this.getPendingOrders();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }),
      2000
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timerActual);
  }

  getPendingOrders = async () => {
    const server_response = await ajax.getShopPendingOrders(this.state.shop_id);

    if (server_response.status == "OK") {
      this.setState({
        items: server_response.details,
      });
    }
  };

  updateStatus = async (id) => {
    if (window.confirm("Are you sure you want to  finish order #" + id)) {
      this.setState({ loading: true });
      const server_response = await ajax.updatePendingOrderStatus(id);
      this.setState({ loading: false });
      if (server_response.status == "OK") {
        this.setState(
          {
            message: server_response.message,
            items: false,
          },
          () => {
            this.getPendingOrders();
          }
        );
      } else {
        this.setState({
          message: server_response.message,
        });
      }
    }
  };

  confirmOrder = async (id) => {
    const a = window.prompt("Confirming order #" + id + " please nter the waiting period in minutes");
    if (!a) {
      window.alert("invalid waiting period")
      return;
    }
    this.setState({ loading: true });
    const server_response = await ajax.confirmPendingOrder(id, a);
    this.setState({ loading: false });
    if (server_response.status == "OK") {
      this.setState(
        {
          message: server_response.message,
          items: false,
        },
        () => {
          this.getPendingOrders();
        }
      );
    } else {
      this.setState({
        message: server_response.message,
      });
    }
  };

  viewItems = async (id) => {
    this.setState({ loading: true });
    const server_response = await ajax.getOrderItems(id);
    this.setState({ loading: false });
    if (server_response.status == "OK") {
      this.setState({
        message: server_response.message,
        order: server_response.details,
      });
    } else {
      this.setState({
        message: server_response.message,
        order: "404",
      });
    }
  };

  render() {
    const { items } = this.state;
    // console.log(items)
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-xl-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="card-title mb-1">Pending Orders</h6>
                  <p className="text-muted card-sub-title">
                    Mando Express Orders thats needs attendance
                  </p>
                  {this.state.loading && <ActivityLoader />}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>
                        <th>Order No</th>
                        <th>Order Total</th>
                        <th>Created by</th>
                        <th>Recipient</th>
                        <th>User Type</th>
                        <th>Payment Method</th>
                        <th>Order Type</th>
                        <th>View Order Items</th>
                        <th>Confirm Order</th>
                        <th>Update Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items &&
                        items !== "404" &&
                        items.map((item, key) => (
                          <tr key={key}>
                            <td>#{key + 1}</td>
                            <td>{item.order_id}</td>
                            <td>{item.total ? item.total : "0"}</td>
                            <td>{item.user.first_name != "Not set" ? item.user.first_name + " " : ""} {item.user.last_name} <br /> {item.user.phone}</td>
                            <td>{item.name} <br /> {item.contact}</td>
                            <td>{item.user_type == 3 ? "Customer" : item.user_type == 4 ? "Agent" : item.user_type == 5 ? "Cashier" : item.user_type == 6 ? "Waiter" : ""}</td>
                            <td>{item.payment_method == 1 ? "Mobile Money" : item.payment_method == 2 ? "Quickpost" : item.payment_method == 3 ? "Pay on Delivery" : item.payment_method == 4 ? "Debt" : ""}</td>
                            <td>{item.order_type == 1 ? "Mando Express" : item.order_type == 2 ? "Jumia" : item.order_type == 3 ? "Glovo" : item.order_type == 4 ? "Online" : ""}</td>
                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                className={"badge p-2 badge-success"}
                                data-target="#modaldemo3"
                                data-toggle="modal"
                                onClick={() => this.viewItems(item.order_id)}
                              >
                                View Order Items
                              </span>
                            </td>

                            <td>
                              {item.confirmed == 1 ? "Confirmed" : <span
                                style={{ cursor: "pointer" }}
                                className={"badge p-2 badge-primary"}
                                onClick={() => this.confirmOrder(item.order_id)}
                              >
                                Confirm Order
                              </span>}
                            </td>

                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                className={"badge p-2 badge-warning"}
                                onClick={() => this.updateStatus(item.order_id)}
                              >
                                Finish Order
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <h5 className="text-center text-danger">
                    {items && items === "404" && "No pending Orders"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ItemsModal
          order={this.state.order}
          title={this.props.title}
          onupdate={this.updateStatus}
          onconfirm={this.confirmOrder}
        />
      </div>
    );
  }
}
