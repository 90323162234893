// const apiHost = "http://192.168.8.102/mando_backend/";
const apiHost = "https://mando.quickpostug.com/";
// const apiHost = "http://localhost/mando_express/";

export default {
  async shopLogin(email, password) {
    let data = {
      email: email,
      password: password,
    };
    try {
      let response = await fetch(apiHost + "shop/login", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addProduct(
    code,
    shop_id,
    name,
    main,
    price,
    sdesc,
    ldesc,
    image,
    extras,
    snacks,
    sides,
    drinks,
    subs
  ) {
    let data = {
      code: code,
      shop_id: shop_id,
      name: name,
      category_id: main,
      price: price,
      short_desc: sdesc,
      long_desc: ldesc,
      image: image,
      extras: extras,
      snacks: snacks,
      sides: sides,
      drinks: drinks,
      subs: subs,
    };
    try {
      let response = await fetch(apiHost + "add/product", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getProducts(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "get/products", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getProduct(product_id) {
    let data = {
      product_id: product_id,
    };
    try {
      let response = await fetch(apiHost + "get/product/info", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getShopInfo(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "get/shop/info", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async secureAccount(user_id, pwd) {
    let data = {
      id: user_id,
      password: pwd,
    };
    try {
      let response = await fetch(apiHost + "secure/account", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createOrderItem(user_id, product_id, shop_id, quantity, total) {
    let data = {
      user_id: user_id,
      product_id: product_id,
      shop_id: shop_id,
      quantity: quantity,
      total: total,
    };
    try {
      let response = await fetch(apiHost + "create/order/item", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getShopPendingOrders(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "get/shop/pending/orders", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getShopPendingOrdersFinished(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "get/shop/pending/orders/finished", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async updatePendingOrderStatus(order_id) {
    let data = {
      order_id: order_id,
    };
    try {
      let response = await fetch(apiHost + "update/pending/order/status", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getOrderItems(order_id) {
    let data = {
      order_id: order_id,
    };
    try {
      let response = await fetch(apiHost + "get/finished/order/info", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async updateProduct(
    product_id,
    code,
    shop_id,
    name,
    main_product,
    price,
    sdesc,
    ldesc,
    image,
    extras,
    snacks,
    sides,
    drinks,
    subs
  ) {
    let data = {
      product_id: product_id,
      code: code,
      shop_id: shop_id,
      name: name,
      category_id: main_product,
      price: price,
      short_desc: sdesc,
      long_desc: ldesc,
      image: image,
      extras: extras,
      snacks: snacks,
      sides: sides,
      drinks: drinks,
      subs: subs,
    };
    try {
      let response = await fetch(apiHost + "update/product", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getCategory(category_id) {
    let data = {
      category_id: category_id,
    };
    try {
      let response = await fetch(apiHost + "get/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listCategories() {
    try {
      let response = await fetch(apiHost + "list/categories", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createCategory(category) {
    let data = {
      category: category,
    };
    try {
      let response = await fetch(apiHost + "create/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async totalSalesToday(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "total/sales/today", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async totalSales(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "total/sales", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async totalFinishedOrders(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "total/finished/orders", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async totalPendingOrders(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "total/pending/orders", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async productsInShop(shop_id) {
    let data = {
      shop_id: shop_id,
    };
    try {
      let response = await fetch(apiHost + "products/in/shop", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteProduct(product_id) {
    let data = {
      product_id: product_id,
    };
    try {
      let response = await fetch(apiHost + "delete/product", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteCategory(category_id) {
    let data = {
      category_id: category_id,
    };
    try {
      let response = await fetch(apiHost + "delete/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async updateCategory(category_id, category) {
    let data = {
      category_id: category_id,
      category: category,
    };
    try {
      let response = await fetch(apiHost + "update/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listAdditiveCategories() {
    try {
      let response = await fetch(apiHost + "list/additive/categories", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createAdditiveSetting(category, name, price) {
    let data = {
      additive_category_id: category,
      name: name,
      price: price,
    };
    try {
      let response = await fetch(apiHost + "create/additive/setting", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createAdditiveCategory(category) {
    let data = {
      category: category,
    };
    try {
      let response = await fetch(apiHost + "create/additive/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteAdditiveCategory(additive_category_id) {
    let data = {
      category_id: additive_category_id,
    };
    try {
      let response = await fetch(apiHost + "delete/additive/category", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getAdditives() {
    try {
      let response = await fetch(apiHost + "list/additive/settings", {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteAdditive(id) {
    let data = {
      additive_id: id,
    };
    try {
      let response = await fetch(apiHost + "delete/additive/setting", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async updateAdditiveSetting(additive_id, name, price, category_id) {
    let data = {
      additive_id: additive_id,
      name: name,
      price: price,
      category_id: category_id,
    };
    try {
      let response = await fetch(apiHost + "update/additive/setting", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listProductAdditives(product_id) {
    let data = {
      product_id: product_id,
    };
    try {
      let response = await fetch(apiHost + "list/additives", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteProductAdditive(additive_id) {
    let data = {
      additive_id: additive_id,
    };
    try {
      let response = await fetch(apiHost + "delete/additive", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createProductAdditive(additive_setting_id, product_id) {
    let data = {
      additive_setting_id: additive_setting_id,
      product_id: product_id,
    };
    try {
      let response = await fetch(apiHost + "create/additive", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listSubProducts(product_id) {
    let data = {
      product_id: product_id,
    };
    try {
      let response = await fetch(apiHost + "list/sub/products", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async deleteSubProduct(sub_product_id) {
    let data = {
      sub_product_id: sub_product_id,
    };
    try {
      let response = await fetch(apiHost + "delete/sub/product", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async createSubProduct(product_id, name, price) {
    let data = {
      product_id: product_id,
      name: name,
      price: price,
    };
    try {
      let response = await fetch(apiHost + "create/sub/product", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async registerWaiter(shop_id, fname, lname, phone, shift, email) {
    let data = {
      shop_id: shop_id,
      fname: fname,
      lname: lname,
      email: email,
      phone: phone,
      shift: shift,
    };
    try {
      let response = await fetch(apiHost + "register/waiter", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async registerAgent(shop_id, fname, lname, phone, delivery_fees, email) {
    let data = {
      shop_id: shop_id,
      fname: fname,
      lname: lname,
      email: email,
      phone: phone,
      delivery_fees: delivery_fees,
    };
    try {
      let response = await fetch(apiHost + "register/agent", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async registerCashier(shop_id, fname, lname, phone, email) {
    let data = {
      shop_id: shop_id,
      fname: fname,
      lname: lname,
      email: email,
      phone: phone,
    };
    try {
      let response = await fetch(apiHost + "register/cashier", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async paymentMethodsPieData(shop_id) {
    let data = {
      shop_id: shop_id
    };
    try {
      let response = await fetch(apiHost + "payment/methods/pie/data", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async orderTypesPieData(shop_id) {
    let data = {
      shop_id: shop_id
    };
    try {
      let response = await fetch(apiHost + "order/types/pie/data", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async confirmPendingOrder(order_id, time) {
    let data = {
      order_id: order_id,
      time: time
    };
    try {
      let response = await fetch(apiHost + "confirm/pending/order", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listWaiters(shop_id) {
    let data = {
      shop_id: shop_id
    };
    try {
      let response = await fetch(apiHost + "list/waiters", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listAgents(shop_id) {
    let data = {
      shop_id: shop_id
    };
    try {
      let response = await fetch(apiHost + "list/agents", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listCashiers(shop_id) {
    let data = {
      shop_id: shop_id
    };
    try {
      let response = await fetch(apiHost + "list/cashiers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },
};
