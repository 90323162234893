import React, { Component } from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import AddAdditiveCategory from "../../Components/Modals/AddAdditiveCategory";
import ManageAdditiveCategories from "../../Components/Modals/ManageAdditiveCategories";
import AdditiveForm from "../../Components/Forms/AdditiveForm";

export default class Additive extends Component {
  buttons = () => {
    return (
      <>
        <button
          className="btn ripple btn-success"
          data-target="#modaldemo4"
          data-toggle="modal"
        >
          {/* <i className="fab fa-amazon-pay"></i> */}
          Manage Categories
        </button>
        <button
          className="btn ripple btn-primary"
          data-target="#modaldemo3"
          data-toggle="modal"
        >
          {/* <i className="fab fa-amazon-pay"></i> */}
          Add Category
        </button>
      </>
    );
  };
  render() {
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Additives"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <AdditiveForm />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
        <AddAdditiveCategory />
        <ManageAdditiveCategories />
      </div>
    );
  }
}
