import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../../Components/Common/ActivityLoader";

class ManageAdditives extends React.Component {
  state = {
    pdts: false,
    loading: false,
    shop_id: functions.shopId(),
  };

  componentDidMount() {
    this.getAdditives();
  }

  getAdditives = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getAdditives();
    this.setState({ loading: false });
    if (server_response.status === "OK") {
      this.setState({
        pdts: server_response.details,
        message: server_response.message,
      });
    } else {
      this.setState({
        pdts: "404",
        message: server_response.message,
      });
    }
  };

  buttons = () => {
    return (
      <>
        {/* <button className="btn ripple btn-primary">
          <i className="fab fa-amazon-pay"></i> Recent Orders
        </button> */}
      </>
    );
  };

  deleteAdditive = (id) => {
    window.alert("Function currently in active!!!! contaxt admin to proceed!!");
    return;
    if (window.confirm("Are you sure you want to delete this Additive?")) {
      const server_response = ajax.deleteAdditive(id);
      console.log(server_response);
      if (server_response) {
        this.setState(
          {
            pdts: false,
          },
          () => {
            this.getAdditives();
          }
        );
      }
    }
  };

  updateAdditive = (id) => {
    if (window.confirm("Are you sure you want to Update this product?")) {
      this.props.history.push({
        pathname: "/update/additive",
        state: { id: id },
      });
    }
  };

  render() {
    const { pdts } = this.state;
    console.log(pdts);
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Manage Additives"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>#No</th>
                            {/* <th>Product Id</th> */}
                            <th>Additive Category</th>
                            <th>Additive Name</th>
                            <th>Additive Price</th>
                            <th>edit</th>
                            <th>delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pdts &&
                            pdts !== "404" &&
                            pdts.map((pdt, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                {/* <td>{pdt["product_id"]}</td> */}
                                <td>{pdt["additive_category"]["category"]}</td>
                                <td>{pdt["name"]}</td>
                                <td>{pdt["price"]}</td>
                                <td className="text-warning">
                                  {" "}
                                  <i
                                    className="fas fa-edit fa-lg"
                                    onClick={() =>
                                      this.updateAdditive(pdt["id"])
                                    }
                                  ></i>
                                </td>
                                <td className="text-danger">
                                  {" "}
                                  <i
                                    className="fas fa-trash fa-lg"
                                    onClick={() =>
                                      this.deleteAdditive(pdt["id"])
                                    }
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {pdts && pdts === "404" && (
                    <h4 className=" text-danger mt-5 ml-5">
                      No Additive Found!!!!
                    </h4>
                  )}
                  {!pdts && <ActivityLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default ManageAdditives;
