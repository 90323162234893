import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";

export default class ManageCategories extends Component {
  state = {
    message: false,
    loading: false,
    categories: false,
    edit: false,
    category: "",
  };

  componentDidMount() {
    this.listCategories();
  }

  listCategories = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.listCategories();
    this.setState({ loading: false });
    if (server_response.status == "OK") {
      this.setState({
        categories: server_response.details,
      });
    } else {
      this.setState({
        categories: "404",
        message: server_response.message,
      });
    }
  };

  deleteCategory = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      const server_response = ajax.deleteCategory(id);
      if (server_response) {
        this.setState(
          {
            categories: false,
          },
          () => {
            this.listCategories();
          }
        );
      }
    }
  };

  updateCategory = (id, category) => {
    this.setState({ category: category, edit: id });
  };

  onUpdate = () => {
    const server_response = ajax.updateCategory(
      this.state.edit,
      this.state.category
    );
    this.setState({ edit: false, category: "" });
    if (server_response) {
      this.setState(
        {
          categories: false,
        },
        () => {
          this.listCategories();
        }
      );
    }
  };
  render() {
    const { categories, edit, category } = this.state;
    console.log(category);
    return (
      <div className="modal" id="modaldemo4">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Manage Product Categories</h6>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <p className="text-center text-danger">
              {this.state.message && this.state.message}
            </p>
            {this.state.loading && <ActivityLoader />}

            <div className="container mt-3">
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Category</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories &&
                      categories != "404" &&
                      categories.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {!edit && item.category}
                            {edit && edit != item.id && item.category}
                            {edit && edit == item.id && (
                              <input
                                type="text"
                                value={category}
                                onChange={(e) => {
                                  this.setState({ category: e.target.value });
                                }}
                              />
                            )}
                          </td>
                          <td className="text-warning">
                            {" "}
                            <i
                              className="fas fa-edit fa-lg"
                              onClick={() =>
                                this.updateCategory(item.id, item.category)
                              }
                            ></i>
                          </td>
                          <td className="text-danger">
                            {" "}
                            <i
                              className="fas fa-trash fa-lg"
                              onClick={() => this.deleteCategory(item.id)}
                            ></i>
                          </td>{" "}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="modal-footer">
              {this.state.category && (
                <button
                  className="btn ripple btn-success"
                  type="button"
                  onClick={this.onUpdate}
                >
                  Update Category
                </button>
              )}
              <button
                className="btn ripple btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
