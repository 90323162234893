import React, { Component } from "react";
import ajax from "../ajax";
import functions from "../functions";
import AlertSuccess from "../Components/Common/AlertSuccess";
import AlertError from "../Components/Common/AlertError";
import ActivityLoader from "../Components/Common/ActivityLoader";

export default class Login extends Component {
  state = {
    email: "",
    pwd: "",
    userInfo: false,
    message: false,
    loading: false,
    rmessage: functions.resetMessage(),
  };

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  onChangePassword = (e) => {
    this.setState({
      pwd: e.target.value,
    });
  };
  onLogin = async () => {
    const { email, pwd } = this.state;

    if (!email || !pwd) {
      this.setState({
        message: <AlertError message="please fill in all fields" />,
      });
    } else {
      this.setState({ loading: true });
      const server_response = await ajax.shopLogin(email, pwd);
      this.setState({ loading: false, pwd: "" });
      console.log(server_response);

      if (server_response.status == "OK") {
        this.setState(
          {
            userInfo: server_response.details,
            message: <AlertSuccess message={server_response.message} />,
          },
          () => {
            localStorage.setItem("shop_id", server_response.details.shop_id);
            localStorage.setItem("user_id", server_response.details.user_id);
            localStorage.setItem("secure", server_response.details.secure);

            window.location.reload();
          }
        );
      } else {
        this.setState({
          message: <AlertError message={server_response.message} />,
        });
      }
    }
  };
  render() {
    return (
      <div>
        <div className="page main-signin-wrapper">
          <div className="row text-center pl-0 pr-0 ml-0 mr-0">
            <div className="col-lg-3 d-block mx-auto">
              <div className="text-center mb-2">
                <img
                  src="images/logo.png"
                  style={{ width: "150px", height: "150px" }}
                  className="header-brand-img"
                  alt="logo"
                />
              </div>
              <div className="card custom-card">
                <div className="card-body">
                  <h4 className="text-center">Login</h4>
                  {this.state.message && this.state.message}
                  {this.state.loading && <ActivityLoader />}
                  <h6 className="text-center text-success">
                    {this.state.rmessage && this.state.rmessage}
                  </h6>
                  <div className="form-group text-left">
                    <label>Email</label>
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      type="text"
                      onChange={this.onChangeEmail}
                    />
                  </div>
                  <div className="form-group text-left">
                    <label>Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your password"
                      type="password"
                      value={this.state.pwd}
                      onChange={this.onChangePassword}
                    />
                  </div>
                  <button
                    style={{ backgroundColor: "#ff8330" }}
                    className="btn ripple  btn-block"
                    onClick={this.onLogin}
                  >
                    Sign In
                  </button>
                  <div className="mt-3 text-center">
                    <p className="mb-1">
                      <a href="#">Forgot password?</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
