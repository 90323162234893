import React from "react";
import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import PlaceOrder from "./Pages/Shop/PlaceOrder";
import FinishedOrders from "./Pages/Shop/FinishedOrders";
import Dashboard from "./Pages/Shop/Dashboard";
import AddProduct from "./Pages/Shop/AddProduct";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import UpdateProduct from "./Pages/Shop/UpdateProduct";
import UpdateAdditive from "./Pages/Shop/UpdateAdditive";
import Additive from "./Pages/Shop/Additive";
import ManageAdditives from "./Pages/Shop/ManageAdditives";
import ProductAdditives from "./Pages/Shop/ProductAdditives";
import SubProducts from "./Pages/Shop/SubProducts";
class App extends React.Component {
  state = {
    loggedIn: false,
    loading: false,
    user_id: false,
    secure: true,
    contact: false,
  };

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin = () => {
    if (localStorage.getItem("user_id")) {
      const secure = localStorage.getItem("secure");

      if (secure == "0") {
        this.setState({
          secure: false,
          loggedIn: true,
        });
      }

      this.setState({
        loggedIn: true,
      });
    }
  };

  render() {
    const { loggedIn, secure, loading } = this.state;
    return (
      <div id="app_container">
        <Router forceRefresh={false}>
          <Switch>
            {!loggedIn && <Route exact path="/" component={Login} />}
            {loggedIn && !secure && (
              <Route exact path="/" component={ResetPassword} />
            )}
            {loggedIn && secure && (
              <Route exact path="/" component={Dashboard} />
            )}

            <Route exact path="/home" component={Dashboard} />
            <Route exact path="/products" component={PlaceOrder} />
            <Route exact path="/finished/orders" component={FinishedOrders} />
            <Route exact path="/additive" component={Additive} />
            <Route exact path="/addpdt" component={AddProduct} />
            <Route exact path="/update/product" component={UpdateProduct} />
            <Route exact path="/update/additive" component={UpdateAdditive} />
            <Route exact path="/manage/additives" component={ManageAdditives} />
            <Route exact path="/sub/products" component={SubProducts} />
            <Route
              exact
              path="/product/additives"
              component={ProductAdditives}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
