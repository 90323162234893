import React, { Component } from "react";
import ActivityLoader from "./../Common/ActivityLoader";

export default class FinItemsModal extends Component {
  additives = (additives) => {
    // console.log(additives);
    return (
      additives &&
      additives.map((additive, key) => (
        <tr key={key}>
          <td>
            <p className="mb-0 text-dark pl-2">
              {additive.additive.additive.name +
                " : Ugx. " +
                additive.additive.additive.price}
            </p>
          </td>
        </tr>
      ))
    );
  };

  printReciept = () => {
    console.log("im in already")
    const printableElements = document.getElementById('print').innerHTML;
    const orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>';
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHtml;
    window.print();
    document.body.innerHTML = oldPage
  }

  // printReciept = () => {


  //   var allbody = document.body.innerHTML;
  //   var reciept = document.getElementById("print").innerHTML;

  //   document.body.innerHTML = reciept;

  //   window.print();

  //   document.body.innerHTML = allbody;
  // }


  render() {
    const orderInfo = this.props.order;
    const order = orderInfo.order_items;
    return (
      <div className="modal" id="modaldemo3">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Order Information</h6>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!orderInfo && <ActivityLoader />}

            {orderInfo && (
              <div className="card-body" id="print">
                <h2 className="text-center">{this.props.title}</h2>
                <div className="row">
                  <div className="col-lg-6">
                    <table>
                      <tr>
                        <td>Order No: </td>
                        <td className="font-weight-bold">
                          #{orderInfo.order_id}
                        </td>
                      </tr>
                      <tr>
                        <td>Status: </td>
                        <td className="font-weight-bold">Finished</td>
                      </tr>
                      <tr>
                        <td>Created by: </td>
                        <td className="font-weight-bold">{orderInfo.user_id.first_name != "Not set" ? orderInfo.user_id.first_name + " " : ""} {orderInfo.user_id.last_name} <br /> {orderInfo.user_id.phone}</td>

                        {/* <td>{orderInfo.name}</td> */}
                      </tr>

                      <tr>
                        <td>Recipient: </td>
                        <td className="font-weight-bold">{orderInfo.name} <br /> {orderInfo.contact}</td>

                        {/* <td>{orderInfo.name}</td> */}
                      </tr>
                      <tr>
                        <td>User type: </td>
                        <td className="font-weight-bold">{orderInfo.user_type == 3 ? "Customer" : orderInfo.user_type == 4 ? "Agent" : orderInfo.user_type == 5 ? "Cashier" : orderInfo.user_type == 6 ? "Waiter" : ""}</td>

                        {/* <td>{orderInfo.name}</td> */}
                      </tr>
                      <tr>
                        <td>Payment Method: </td>
                        <td className="font-weight-bold">{orderInfo.payment == 1 ? "Mobile Money" : orderInfo.payment == 2 ? "Quickpost" : orderInfo.payment == 3 ? "Pay on Delivery" : orderInfo.payment == 4 ? "Debt" : ""}</td>

                        {/* <td>{orderInfo.name}</td> */}
                      </tr>
                      <tr>
                        <td>Order Type: </td>
                        <td className="font-weight-bold">{orderInfo.order_type == 1 ? "Mando Express" : orderInfo.order_type == 2 ? "Jumia" : orderInfo.order_type == 3 ? "Glovo" : orderInfo.order_type == 4 ? "Online" : ""}</td>

                        {/* <td>{orderInfo.name}</td> */}
                      </tr>
                      <tr>
                        <td>Confirmed: </td>
                        <td className="font-weight-bold">
                          {orderInfo.confirmed == 1 ? "Confirmed" : "Not Confirmed"}
                        </td>
                        {/* <td>{orderInfo.name}</td> */}
                      </tr>
                      <tr>
                        <td>Date Created: </td>
                        <td className="font-weight-bold">{orderInfo.date}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="row">
                  {order &&
                    order !== "404" &&
                    order.map((item, key) => (
                      <div key={key} className="col-lg-12">
                        <div className="p-2 border mt-1 mb-1">
                          <table>
                            <tr>
                              <td className="text-muted">Product Name</td>
                              <td>
                                <h5 className="mb-0 pl-2">
                                  {item.sub_product &&
                                    item.product.name +
                                    " ( " +
                                    item.sub_product.name +
                                    " ) "}
                                  {!item.sub_product && item.product.name}
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">Unit Price</td>
                              <td>
                                <p className="mb-0 pl-2 text-dark">
                                  {item.sub_product &&
                                    "Ugx. " + item.sub_product.price}
                                  {!item.sub_product &&
                                    "Ugx. " + item.product.price}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">
                                Product description
                              </td>
                              <td>
                                <p className="mb-0 pl-2 text-dark">
                                  {item.product.long_desc}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">Quantity</td>
                              <td>
                                <p className="mb-0 pl-2 text-dark text-success">
                                  {item.quantity}
                                </p>
                              </td>
                            </tr>

                            {this.additives(item.additives)}

                            <tr>
                              <td className="text-muted">Sub Total</td>
                              <td>
                                <h5 className="mb-0 pl-2">{item.total}</h5>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <table className="table table-bordered m-0 p-0">
                      <tr className="m-0 p-0">
                        <td>
                          <h5 className="p-0 m-0">Order Total: </h5>
                        </td>
                        <td>
                          <h4 className="p-0 m-0">Ugx. {orderInfo.total}</h4>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            )}


            <div className="modal-footer">
              {/* <button
                className="btn ripple btn-success"
                type="button"
                onClick={this.printReciept}
              >
                Print
              </button> */}
              <button
                className="btn ripple btn-warning"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
