import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "./../Common/ActivityLoader";
import AlertSuccess from "../Common/AlertSuccess";
import AlertError from "../Common/AlertError";

export default class AddCategory extends Component {
  state = {
    category: "",
    message: false,
    loading: false,
  };
  onSubmit = async () => {
    if (!this.state.category) {
      this.setState({
        message: <AlertError message="fill in the category please" />,
      });
    } else {
      this.setState({ loading: true });
      const server_response = await ajax.createCategory(this.state.category);
      this.setState({
        loading: false,
        category: "",
      });

      if (server_response.status == "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.message} />,
        });
      } else {
        this.setState({
          info: <AlertError message={server_response.message} />,
        });
      }
    }
  };
  render() {
    return (
      <div className="modal" id="modaldemo3">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Add Products Category</h6>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <p className="text-center text-danger">
              {this.state.message && this.state.message}
            </p>
            {this.state.loading && <ActivityLoader />}

            <div className="container mt-3">
              <div className="form-group">
                <label>Category: </label>
                <input
                  type="text"
                  placeholder="category"
                  className="form-control"
                  value={this.state.category}
                  onChange={(e) => this.setState({ category: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn ripple btn-success"
                type="button"
                onClick={this.onSubmit}
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
