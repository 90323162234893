import React from 'react';


class AlertSuccess extends React.Component {
  

  render(){
    
      return (
        <>
        {this.props.message && <div className="alert alert-success" role="alert">
            <strong>Success! </strong>{this.props.message}
        </div>}
        </>
      );
    
 
  }
}

export default AlertSuccess;