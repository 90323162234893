import React from "react";
import { Link } from "react-router-dom";
import functions from "../../functions";

class PageHeader extends React.Component {
  render() {
    return (
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            {this.props.title}
          </h2>
          {this.props.previousPageTitle && (
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {this.props.previousPageTitle}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.title}
              </li>
            </ol>
          )}
        </div>

        <div className="btn btn-list">{this.props.buttons}</div>
      </div>
    );
  }
}

export default PageHeader;
