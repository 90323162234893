import React from "react";
import { Link } from "react-router-dom";
import functions from "../../functions";
import ajax from "../../ajax";
import $ from "jquery";
import ActivityLoader from "./ActivityLoader";
import ProfileMenu from "./ProfileMenu";
import RegisterUser from "../Modals/RegisterUser";
import Employees from "../Modals/Employees";

class TopBar extends React.Component {
  state = {
    shop_id: functions.sessionGuard(),
    person: 'Waiter'
  };

  componentDidMount() {
    this.closeSideBar();

  }



  toggleSideBar = () => {
    let element = document.getElementsByTagName("body");
    if (window.matchMedia("(min-width: 768px)").matches) {
      element[0].classList.toggle("main-sidebar-hide");
    } else {
      element[0].classList.toggle("main-sidebar-show");
    }
  };

  openDrawer = () => {
    setTimeout(() => {
      document.getElementById("system_drawer").classList.add("sidebar-open");
    }, 100);
  };

  closeSideBar = () => {
    let element = document.getElementsByTagName("body");
    if (window.matchMedia("(min-width: 768px)").matches) {
      element[0].classList.add("main-sidebar-hide");
    } else {
      element[0].classList.add("main-sidebar-show");
    }
  };

  toggleProfileAccess = () => {
    let element = document.getElementById("profile-menu-access");
    element.classList.toggle("show");
  };

  logos = () => {
    return (
      <Link className="main-logo d-lg-none" to="/">
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          style={{ width: "50px", height: "50px" }}
          className="header-brand-img desktop-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          style={{ width: "50px", height: "50px" }}
          className="header-brand-img icon-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/logo.png"}
          style={{ width: "50px", height: "50px" }}
          className="header-brand-img desktop-logo theme-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          style={{ width: "50px", height: "50px" }}
          className="header-brand-img icon-logo theme-logo"
          alt="logo"
        />
      </Link>
    );
  };

  toggleDropdown = (idSelector) => {
    setTimeout(() => {
      document.getElementById(idSelector).classList.toggle("show");
    }, 100);
  };

  onClickSearch = () => {
    $("#mdl-search").modal("show");
  };

  onLogout = () => {
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("shop_id");
    window.localStorage.removeItem("shop_name");
    window.localStorage.removeItem("secure");
    window.localStorage.removeItem("message");

    window.location.replace("/");
  };

  onPressperson = (person) => {
    this.setState({ person: person })
  }

  render() {
    return (
      <>
        <div
          style={{ position: "abasolute", top: "0px" }}
          className="main-header side-header sticky"
        >
          <div className="container-fluid">
            <div className="main-header-left flex-box">
              <this.logos />
              <a
                className="main-header-menu-icon link"
                to="#"
                onClick={this.toggleSideBar}
                id="mainSidebarToggle"
              >
                <span></span>
              </a>
            </div>
            <div className="main-header-right">

              <div className="dropdown main-header-notification">
                <a className="nav-link icon" href="#">
                  <i className="fe fe-bell" style={{ fontSize: '23px' }} />
                  <span className="badge bg-danger" style={{ borderRadius: '50%' }}>1</span>
                </a>
                <div className="dropdown-menu">
                  <div className="header-navheading">
                    <p className="main-notification-text">You have 1 unread notifications</p>
                  </div>
                  <div style={{ maxHeight: '400px', overflow: 'scroll' }} className="main-notification-list">
                    <div className="media new">
                      <div className="media-body">
                        <p>New order that needs confirmation</p><span>Oct 15 12:32pm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ProfileMenu onClick={this.onPressperson} />


            </div>
          </div>
        </div>
        <RegisterUser person={this.state.person} />

        <Employees />

      </>
    );
  }

  itemsInCart = {
    width: "20px",
    height: "20px",
    color: "red",
    backgroundColor: "#fff",
    borderRadius: "50%",
    lineHeight: "20px",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "16px",
    display: "inline-block",
    marginBottom: "20px",
    marginRight: "30px",
  };
}

export default TopBar;
