import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import PageHeader from "../../Components/Common/PageHeader";
import TopBar from "../../Components/Common/TopBar";
import functions from "../../functions";
import { Link } from "react-router-dom";
import Form from "../../Components/Forms/Form";
import AddCategory from "../../Components/Modals/AddCategory";
import ManageCategories from "../../Components/Modals/ManageCategories";

class AddProduct extends React.Component {
  buttons = () => {
    return (
      <>
        <button
          className="btn ripple btn-success"
          data-target="#modaldemo4"
          data-toggle="modal"
        >
          {/* <i className="fab fa-amazon-pay"></i> */}
          Manage Categories
        </button>
        <button
          className="btn ripple btn-primary"
          data-target="#modaldemo3"
          data-toggle="modal"
        >
          {/* <i className="fab fa-amazon-pay"></i> */}
          Add Category
        </button>
      </>
    );
  };

  render() {
    return (
      <div classNmae="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Add Product"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />
            <Form />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            bottom: "0px",
          }}
        >
          <Footer />
        </div>
        <AddCategory />
        <ManageCategories />
      </div>
    );
  }
}

export default AddProduct;
