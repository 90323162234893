import React from 'react'
import { Link } from 'react-router-dom'

class DashCard extends React.Component{

    render(){
        return(


            <div className="col-sm-6 col-md-3 col-xl-3 col-lg-6">
                <Link to={this.props.href}>
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="icon-service bg-secondary-transparent rounded-circle text-secondary">
										<i className={this.props.icon}></i>
									</div>
									<p className="mb-1 text-muted">{this.props.title}</p>
									<h3 className="mb-0">{this.props.value}</h3>
								</div>
							</div>
                </Link>
            </div>
            
           )
    }

}

export default DashCard