import FormData from "form-data";
import React, { Component } from "react";
import ajax from "../../ajax";
import functions from "../../functions";
import ActivityLoader from "../Common/ActivityLoader";
import AlertSuccess from "../Common/AlertSuccess";
import AlertError from "../Common/AlertError";

export default class UpdateForm extends Component {
  state = {
    shop_id: functions.sessionGuard(),
    imageName: "Choose Image",
    image: "none",
    code: "",
    main: "",
    name: "",
    price: "",
    sdesc: "",
    ldesc: "",
    info: "",
    snacks: "",
    sides: "",
    extras: "",
    drinks: "",
    subs: "",
    loading: false,
    categories: false,
    product: false,
    productId: this.props.product_id,
  };

  componentDidMount() {
    this.listCategories();
    this.getProductInfo();
  }

  onChangeImage = (event) => {
    this.setState({
      imageName: event.target.value,
    });
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      this.setState({
        image: e.target.result,
      });
    };
  };
  onChangeCode = (event) => {
    this.setState({
      code: event.target.value,
    });
  };
  onchangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  onChangeMain = (event) => {
    this.setState({
      main: event.target.value,
    });
  };
  onchangePrice = (event) => {
    this.setState({
      price: event.target.value,
    });
  };
  onchangeSdesc = (event) => {
    this.setState({
      sdesc: event.target.value,
    });
  };
  onchangeLdesc = (event) => {
    this.setState({
      ldesc: event.target.value,
    });
  };
  onChangeExtras = (event) => {
    this.setState({
      extras: event.target.value,
    });
  };
  onChangeSides = (event) => {
    this.setState({
      sides: event.target.value,
    });
  };
  onChangeSnacks = (event) => {
    this.setState({
      snacks: event.target.value,
    });
  };
  onChangeDrinks = (event) => {
    this.setState({
      drinks: event.target.value,
    });
  };
  onChangeSubs = (event) => {
    this.setState({
      subs: event.target.value,
    });
  };

  onsubmit = async () => {
    const {
      shop_id,
      image,
      name,
      price,
      sdesc,
      ldesc,
      code,
      main,
      extras,
      sides,
      snacks,
      drinks,
      subs,
    } = this.state;
    if (
      !image ||
      !name ||
      !price ||
      !sdesc ||
      !ldesc ||
      !code ||
      !main ||
      !extras ||
      !snacks ||
      !sides ||
      !drinks ||
      !subs
    ) {
      this.setState({
        info: <AlertError message="fill all fields please" />,
      });
    } else {
      this.setState({ loading: true });
      const server_response = await ajax.updateProduct(
        this.state.productId,
        code,
        shop_id,
        name,
        main,
        price,
        sdesc,
        ldesc,
        image,
        extras,
        snacks,
        sides,
        drinks,
        subs
      );
      // console.log(server_response);
      this.setState({
        loading: false,
        code: "",
        name: "",
        main: "",
        price: "",
        sdesc: "",
        ldesc: "",
        snacks: "",
        sides: "",
        extras: "",
        drinks: "",
        subs: "",
        imageName: "Choose Image",
      });
      if (server_response.status == "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.message} />,
        });
      } else {
        this.setState({
          info: <AlertError message={server_response.message} />,
        });
      }
    }
  };
  getProductInfo = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getProduct(this.state.productId);
    this.setState({ loading: false });
    // console.log(server_response);
    if (server_response.status == "OK") {
      this.setState({
        product: server_response.details,
        main: server_response.details.category_id,
        code: server_response.details.code,
        name: server_response.details.name,
        price: server_response.details.price,
        sdesc: server_response.details.short_desc,
        ldesc: server_response.details.long_desc,
        snacks: server_response.details.snacks.toString(),
        sides: server_response.details.sides.toString(),
        extras: server_response.details.extras.toString(),
        drinks: server_response.details.drinks.toString(),
        subs: server_response.details.subs.toString(),
      });
    } else {
      this.setState({
        product: "404",
        message: server_response.message,
      });
    }
  };

  listCategories = async () => {
    const server_response = await ajax.listCategories();
    if (server_response.status == "OK") {
      this.setState({
        categories: server_response.details,
      });
    } else {
      this.setState({
        categories: "404",
        message: server_response.message,
      });
    }
  };
  render() {
    const {
      info,
      image,
      snacks,
      sides,
      extras,
      drinks,
      name,
      price,
      sdesc,
      ldesc,
      code,
      main,
      subs,
      categories,
    } = this.state;
    // console.log(subs);
    return (
      <div>
        <div className="row mb-5">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="card-title mb-1">Update Product</h6>
                  <p className="text-muted card-sub-title">
                    All fields in this form are compalsary!
                  </p>
                  {this.state.loading && <ActivityLoader />}
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    {this.state.info && info}
                    <div className="">
                      <div className="form-group">
                        <label className="">Product code</label>
                        <input
                          className="form-control"
                          required=""
                          type="text"
                          value={code}
                          onChange={this.onChangeCode}
                        />
                      </div>
                      <div className="form-group">
                        <label className="">Product Name</label>
                        <input
                          className="form-control"
                          required=""
                          type="text"
                          value={name}
                          onChange={this.onchangeName}
                        />
                      </div>
                      <div className="form-group">
                        <label className="">Product Category</label>
                        <select
                          className="form-control"
                          value={main}
                          onChange={this.onChangeMain}
                        >
                          <option unselectable> -- select category --</option>
                          {categories &&
                            categories != "404" &&
                            categories.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.category}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="">Product Price</label>
                        <div className="pos-relative">
                          <input
                            className="form-control pd-r-80"
                            required=""
                            type="text"
                            value={price}
                            onChange={this.onchangePrice}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="">Short Description</label>
                        <div className="pos-relative">
                          <input
                            className="form-control pd-r-80"
                            required=""
                            type="text"
                            value={sdesc}
                            onChange={this.onchangeSdesc}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="">Long Description</label>
                        <div className="pos-relative">
                          <input
                            className="form-control pd-r-80"
                            required=""
                            type="text"
                            value={ldesc}
                            onChange={this.onchangeLdesc}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-sm-3">
                            <label className>Extras</label>
                            <select
                              className="form-control select select2"
                              value={extras}
                              onChange={this.onChangeExtras}
                            >
                              <option label="Has Extras ?" />
                              <option value="1">yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="col-sm-3 mg-t-20 mg-sm-t-0">
                            <label className>Sides</label>
                            <select
                              className="form-control select select2"
                              value={sides}
                              onChange={this.onChangeSides}
                            >
                              <option label="Has Sides ?" />
                              <option value="1">yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="col-sm-3 mg-t-20 mg-sm-t-0">
                            <label className>Snacks</label>
                            <select
                              className="form-control select select2"
                              value={snacks}
                              onChange={this.onChangeSnacks}
                            >
                              <option label="Has snacks ?" />
                              <option value="1">yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="col-sm-3 mg-t-20 mg-sm-t-0">
                            <label className>Drinks</label>
                            <select
                              className="form-control select select2"
                              value={drinks}
                              onChange={this.onChangeDrinks}
                            >
                              <option label="Has Drinks ?" />
                              <option value="1">yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="">Sub Products</label>

                        <select
                          className="form-control select select2"
                          value={subs}
                          onChange={this.onChangeSubs}
                        >
                          <option label="Has Sub Products ?" />
                          <option value="1">yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="">
                          Select Image
                          <span className="text-danger"> *optional</span>
                        </label>

                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="customFile"
                            type="file"
                            onChange={this.onChangeImage}
                          />{" "}
                          <label class="custom-file-label" for="customFile">
                            {this.state.imageName}
                          </label>
                        </div>
                      </div>

                      <button
                        onClick={this.onsubmit}
                        className="btn ripple btn-main-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
